import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledButton = styled.button`
  background-color: ${theme.colors.turquoise};
  border-color: inherit;
  border-radius: 10px;
  color: ${theme.colors.white};
  cursor: ${(props) => (props.customButton ? 'not-allowed' : 'pointer')};
  font-weight: 550;
  font-size: 40px;
  height: 70px;
  margin-bottom: 20px;
  padding-top: 5px;
  opacity: ${(props) => (props.customButton ? '0.6' : '1')};
  text-align: center;
  width: 400px;

  &:hover {
    background-color: ${theme.colors.midnightBlue};
    box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    height: 35px;
    width: 200px;
  }
`

export const StyledNumber = styled.div`
  background-color: ${theme.colors.harlequin};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 125px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 550;
  font-size: 36px;

  @media (max-width: 1024px) {
    font-size: 18px;
    height: 30px;
    width: 62px;
  }
`

export const StyledDisplayNumber = styled.div`
  height: 64px;
  width: 134px;

  @media (max-width: 1024px) {
    height: 32px;
    width: 67px;
  }
`

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
