import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 273px;
  height: 24px;
  padding: 16px;
  gap: 12px;
  background-color: ${(props) =>
    props.active ? theme.colors.solitude : 'transparent'};

  div {
    width: 24px;
    height: 24px;
  }

  span {
    align-content: center;
    width: 237px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;

    color: ${(props) =>
      props.imgAlt == 'sign-out'
        ? theme.colors.cinnabar
        : theme.colors.eastBay};
  }

  &:hover {
    background-color: ${theme.colors.solitude};
  }

  @media (max-width: 540px) {
    width: 85%;
    height: 18px;
    padding: 14px;
    gap: 10px;

    div {
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    span {
      width: 113px;
      height: 15px;
      font-size: 12px;
    }
  }
`
