import { RoutesApp } from '../../components'
import { StyledApp } from './style'
import { ThemeProvider } from 'styled-components'
import theme from '../../assets/styles/theme/default'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <ThemeProvider theme={theme.grid}>
        <StyledApp>
          <RoutesApp />
        </StyledApp>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

export default App
