import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.solitude};
`
