import styled, { css } from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledHomePage = styled.div`
  display: flex;
  justify-content: right;
  background: url(${(props) => props.background});
  background-size: cover;
  min-height: 100vh;

  @media (max-width: 540px) {
    justify-content: center;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${theme.colors.white};

  @media (max-width: 540px) {
    opacity: 0.96;
    margin: 16px;
  }
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 66.5px;

  @media (max-width: 540px) {
    margin: 16px;
  }
`

export const StyledImg = styled.img`
  height: 142px;
  width: 154px;
  margin-top: 50px;
  margin-bottom: 36px;

  ${(props) =>
    props.selected &&
    css`
      margin-bottom: 20px;
    `}

  @media (max-width: 540px) {
    height: 101px;
    width: 115px;
  }
`

export const StyledSubTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 36px;

  ${(props) =>
    props.selected &&
    css`
      margin-bottom: 32px;
    `}

  @media (max-width: 540px) {
    font-weight: 400;
    font-size: 20px;
    margin: 0px 0px 10px 0px;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0px 30px;

  @media (max-width: 540px) {
    gap: 0px 20px;
    margin: 0px 10px;
  }
`

export const StyledFormWrapper = styled.div`
  visibility: ${(props) => (props.label ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-top: 40px;

  .input-wrapper {
    min-height: inherit;
    gap: 6px 0px;

    .invalid-message {
      margin-top: -20px;
      margin-bottom: 10px;
    }
  }

  .input-tipTexImg {
    margin-left: 0px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: inherit;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    input {
      margin-left: 0px;
      width: 90%;
      line-height: 24px;
    }
  }

  Button {
    width: 100%;
    height: 56px;
    gap: 12px;

    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  @media (max-width: 540px) {
    margin-top: 24px;
    width: auto;

    .input-container {
      display: flex;
      flex-direction: column;

      input {
        margin-left: 0px;
        width: 82.4%;
      }
    }

    Button {
      width: 100%;

      span {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
`

export const StyledFooter = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  margin-top: 30px;
`
