import { StyledFooter } from './style'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

const Footer = ({ backgroundcolor, leftImage, rightImage }) => (
  <StyledFooter data-cy="footer" backgroundcolor={backgroundcolor}>
    <Grid fluid={true} className="grid-footer">
      <Row className="wrap-footer">
        <Col sm={6} xs={6} className="left-logo">
          <img src={leftImage} alt="Logo da rebase" />
        </Col>
        <Col sm={6} xs={6} className="right-logo">
          <img src={rightImage} alt="Feito com amor por rebasers" />
        </Col>
      </Row>
    </Grid>
  </StyledFooter>
)

export default Footer
