import React from 'react'
import { StyledRuleContainer } from './style'
import { RuleCheckBox } from '../index'

const RuleContainer = ({ activeRules, setActiveRules }) => {
  const handleRuleChange = (rule) => {
    setActiveRules((previousRules) =>
      previousRules.includes(rule)
        ? previousRules.filter((currentRule) => currentRule !== rule)
        : [...previousRules, rule]
    )
  }

  const rules = [
    'FullCard',
    'TipsCard',
    'DiagonalCard',
    'HorizontalLine',
    'VerticalLine',
  ]

  return (
    <StyledRuleContainer>
      {rules.map((rule) => (
        <RuleCheckBox
          key={rule}
          ruleType={rule}
          activeRules={activeRules}
          onRuleChange={handleRuleChange}
        />
      ))}
    </StyledRuleContainer>
  )
}

export default RuleContainer
