import React from 'react'
import {
  StyledCheckBorder,
  StyledInput,
  StyledInputLabel,
  StyledLabel,
} from './style'
import {
  DiagonalCardImg,
  FullCardImg,
  TipsCardImg,
  HorizontalLineImg,
  VerticalLineImg,
} from '../../assets/images'

const RuleCheckBox = ({ ruleType, activeRules, onRuleChange }) => {
  const ruleDetails = {
    FullCard: { label: 'Cartela Cheia', img: FullCardImg, alt: 'Full Card' },
    TipsCard: { label: 'Pontas', img: TipsCardImg, alt: 'Tips Card' },
    DiagonalCard: {
      label: 'Diagonais',
      img: DiagonalCardImg,
      alt: 'Diagonal Card',
    },
    HorizontalLine: {
      label: 'Sequência horizontal (Linhas)',
      img: HorizontalLineImg,
      alt: 'Horizontal Line',
    },
    VerticalLine: {
      label: 'Sequência vertical (Colunas)',
      img: VerticalLineImg,
      alt: 'Vertical Line',
    },
  }

  const isChecked = activeRules.includes(ruleType)

  const activeCard = ruleDetails[ruleType]

  const handleCheckboxChange = () => {
    onRuleChange(ruleType)
  }

  return (
    <StyledCheckBorder
      QuintupleCard={
        ruleType === 'HorizontalLine' || ruleType === 'VerticalLine'
      }
      DoubleCard={ruleType === 'DiagonalCard'}
      isChecked={isChecked}
      data-cy="rule-checkbox"
      htmlFor={`rule-checkbox-${ruleType}`}
      hasFullCard={activeRules.includes('FullCard') && ruleType !== 'FullCard'}
    >
      <StyledInputLabel
        DoubleCard={ruleType === 'DiagonalCard'}
        QuintupleCard={
          ruleType === 'HorizontalLine' || ruleType === 'VerticalLine'
        }
      >
        <StyledInput
          hasFullCard={
            activeRules.includes('FullCard') && ruleType !== 'FullCard'
          }
        >
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={
              activeRules.includes('FullCard') && ruleType !== 'FullCard'
            }
            id={`rule-checkbox-${ruleType}`}
          />
        </StyledInput>
        <StyledLabel>
          <p>{activeCard.label}</p>
        </StyledLabel>
      </StyledInputLabel>
      <img src={activeCard.img} alt={activeCard.alt} />
    </StyledCheckBorder>
  )
}

export default RuleCheckBox
