import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import theme from '../../assets/styles/theme/default'

export const StyledCardCell = styled(Col)`
  background-color: ${(props) =>
    props.marked === 'true' ? theme.colors.harlequin : theme.colors.white};
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
  margin: 6px 8px;
  height: 75px;
  widht: 78px;
  border-radius: 4px;

  p {
    color: ${theme.colors.melanzen};
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 42px;
  }

  @media (max-width: 540px) {
    height: 50px;

    p {
      font-size: 150%;
    }
  }
`
