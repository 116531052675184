import React from 'react'
import { StyledButton } from './style'
import { AltCheck } from '../../assets/images'

export const DropdownOption = ({
  img,
  imgAlt,
  label,
  action,
  isActive = false,
}) => {
  return (
    <StyledButton
      onClick={action}
      active={isActive}
      imgAlt={imgAlt}
      data-cy="dropdown-option"
    >
      <div>
        <img src={img} alt={imgAlt} />
      </div>
      <span>{label}</span>
      <div>{isActive && <img src={AltCheck} alt="active" />}</div>
    </StyledButton>
  )
}

export default DropdownOption
