import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledCard = styled.div`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-base {
    background-color: ${theme.colors.turquoise};
    display: flex;
    place-items: center;
    padding: unset;
    height: 570px;
    width: 531px;
    border-radius: 12px;
  }

  .grid-card {
    flex: 1;
  }

  .card-header {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: 7px;
    height: 75px;
    width: 78px;
    border-radius: 4px;
    background-color: ${theme.colors.jordyBlue};

    p {
      color: ${theme.colors.white};
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 48px;
    }

    &.bingo {
      background-color: ${theme.colors.harlequin};

      p {
        color: ${theme.colors.midnightBlue};
      }
    }
  }

  .card-img {
    background-color: ${theme.colors.harlequin};
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: 6px 8px;
    height: 75px;
    border-radius: 4px;

    img {
      width: 60%;
    }
  }

  @media (max-width: 540px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 0px;

    .card-base {
      width: 350px;
      height: 400px;
      margin-top: 20px;
    }

    .card-header {
      height: 50px;

      p {
        font-size: 150%;
      }
    }

    .card-img {
      height: 50px;
    }
  }
`
