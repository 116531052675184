export const generateCard = (hashNumbers) => {
  const cardNumbers = {}

  Object.keys(hashNumbers).forEach((key) => {
    const length = key === 'N' ? 4 : 5
    // contagem de numero que irá extrair do array
    cardNumbers[key] = hashNumbers[key].slice(0, length)
  })

  return cardNumbers
}
