import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledCell = styled.div`
  padding: 20px 0px;

  .wrapper-row {
    display: flex;
    justify-content: space-around;
  }

  .cell {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .letter {
    background-color: ${theme.colors.turquoise};
    color: ${theme.colors.white};
    font-size: 35px;
    display: flex;
  }

  .number {
    border: 1px solid ${theme.colors.darkGray};
    color: ${theme.colors.navyBlue1};
    font-size: 30px;
    margin: 4px 0px;
    display: flex;
  }

  .true {
    background-color: ${theme.colors.harlequin};
    transition: background-color 0.5s ease;
  }

  @media (max-width: 1024px) {
    .letter {
      font-size: 20px;
    }

    .number {
      font-size: 15px;
      margin: 4px 0px;
    }

    .cell {
      border-radius: 2px;
      height: 20px;
      margin: 2px 4px;
      width: 32px;
      justify-content: center;
      align-items: center;
    }
  }
`
