import React from 'react'
import { StyledMenuButton } from './style'
import { Col, Grid } from 'react-styled-flexboxgrid'

const MenuButton = ({ label, text, onClick, img, alt, selected }) => {
  return (
    <StyledMenuButton
      onClick={onClick}
      selected={selected}
      data-cy={`button-${label}`}
    >
      <Grid fluid={true} className="grid" data-cy="button-grid">
        <Col data-cy="img-col">
          <img src={img} alt={alt} className="img-button" />
        </Col>
        <Col className="div-tex" data-cy="span-col">
          <span>{label}</span>
          <h3>{text}</h3>
        </Col>
      </Grid>
    </StyledMenuButton>
  )
}

export default MenuButton
