import styled, { css } from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledMenuButton = styled.button`
  cursor: pointer;
  align-items: center;
  border: none;
  background-color: ${theme.colors.white};

  .grid {
    display: flex;
    flex-direction: column;
    width: 172px;
    height: 190px;
    padding: 20px 40px;
    border-radius: 6px;
    border: 1.2px solid ${theme.colors.linkWater};
    gap: 12px 0px;

    div {
      padding: 0px;
    }

    img {
      width: 110px;
      height: 126px;
    }

    .div-tex {
      height: 53px;
    }

    &:hover {
      border-color: ${theme.colors.turquoise};
      background-color: ${theme.colors.solitude};
      box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};

      span {
        color: ${theme.colors.turquoise};
      }
    }
  }

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${theme.colors.cloudBurst};
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: ${theme.colors.sanJuan};
  }

  ${(props) =>
    props.selected &&
    css`
      .grid {
        border-color: ${theme.colors.turquoise};
        background-color: ${theme.rgba.babyBlue005};
      }

      span {
        color: ${theme.colors.turquoise};
      }
    `}

  @media (max-width: 540px) {
    .grid {
      width: 108px;
      height: 124px;
      padding: 10px;
      gap: 5px 0px;

      .div-tex {
        height: 32px;
      }

      img {
        width: 70px;
        height: 80px;
      }
    }

    span {
      font-size: 12px;
      line-height: 20px;
    }

    h3 {
      font-size: 10px;
      line-height: 12px;
    }

    .img-button {
      width: 65%;
    }
  }
`
