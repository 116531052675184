import { useContext, useEffect, useState } from 'react'
import {
  StyledButton,
  StyledNumber,
  StyledDisplayNumber,
  StyledWrapper,
} from './style'
import { hashNumbers } from '../../utils'
import { BingoDrawerContext } from '../../context/BingoDrawerContext'

const Caller = ({ ballCage, removeBallcage }) => {
  const [getBall, setBall] = useState('')
  const [letterNumber, setLetterNumber] = useState('')

  const {
    orderedDrawnNumbers,
    setOrderedDrawnNumbers,
    drawnNumbers,
    setDrawnNumbers,
  } = useContext(BingoDrawerContext)

  useEffect(() => {
    if (!getBall) return

    const letter = Object.keys(hashNumbers).find((key) =>
      hashNumbers[key].includes(getBall)
    )

    const selectedNumbers = {
      ...drawnNumbers,
      [letter]: [...drawnNumbers[letter], getBall],
    }

    setOrderedDrawnNumbers([...orderedDrawnNumbers, getBall])
    setDrawnNumbers(selectedNumbers)
    setLetterNumber(`${letter}-${getBall}`)
  }, [getBall])

  const handleClick = () => {
    setBall(ballCage.pop())
    removeBallcage(ballCage)
  }

  const disableButton = () => {
    return !ballCage.length
  }

  return (
    <StyledWrapper>
      <StyledButton
        customButton={disableButton()}
        onClick={handleClick}
        disabled={disableButton()}
        data-cy="bingo-button"
      >
        Sortear número
      </StyledButton>
      <StyledDisplayNumber>
        {getBall && (
          <StyledNumber data-cy="bingo-number">{letterNumber}</StyledNumber>
        )}
      </StyledDisplayNumber>
    </StyledWrapper>
  )
}

export default Caller
