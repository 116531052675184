export const shuffleArray = (array, token) => {
  Math.seedrandom(token)
  const custonArray = [...array]

  // Embaralhando itens de um array
  for (let index = custonArray.length - 1; index > 0; index--) {
    const newIndex = Math.floor(Math.random() * (index + 1))
    ;[custonArray[index], custonArray[newIndex]] = [
      custonArray[newIndex],
      custonArray[index],
    ]
  }

  return custonArray
}
