import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > img:first-child {
    width: 15px;
    height: 15px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
  }
`

export const StyledButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${theme.colors.white};
  border: none;
`

export const StyledContent = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50px;
  right: 20px;
  width: 306px;
  padding: 1px;
  border: 1px solid ${theme.colors.babyBlue};
  background-color: ${theme.colors.white};

  span {
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 540px) {
    width: 50%;
  }
`
