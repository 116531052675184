import React, { useContext, useRef, useState } from 'react'
import {
  StyledContainer,
  StyledCloseImg,
  StyledHeader,
  StyledFooter,
  StyledRulesContainer,
  StyledRuleTipText,
  StyledBody,
} from './style'
import {
  CloseButton,
  BlueCheck,
  BlueKey,
  CheckOutline,
} from '../../assets/images'
import {
  Button,
  CardResult,
  Input,
  RuleContainer,
} from '../../components/index'
import { AppContext } from '../../context/AppContext'
import { useOutsideClick } from '../../hooks'

const CheckCardModal = () => {
  const [token, setToken] = useState('')
  const [conferenceStage, setConferenceStage] = useState(true)
  const [activeRules, setActiveRules] = useState([])

  const {
    isDropdownVisible,
    setDropdownVisibility,
    isActionDisabled,
    setActionDisabled,
  } = useContext(AppContext)

  const CheckModalRef = useRef(null)

  const handleTokenChange = (e) => {
    setToken(e.target.value)
  }

  const handleCloseButton = () => {
    setDropdownVisibility(false)
    setActiveRules([])
    setActionDisabled(true)
  }

  const handleConferenceStage = () => {
    if (!isActionDisabled && activeRules.length > 0) {
      setConferenceStage(false)
    }
  }

  useOutsideClick(CheckModalRef, handleCloseButton, isDropdownVisible)

  return (
    <StyledContainer
      ref={CheckModalRef}
      data-cy="check-card"
      conference={conferenceStage}
    >
      <StyledCloseImg
        src={CloseButton}
        alt="close-button"
        onClick={handleCloseButton}
        data-cy="close-btn"
      />
      {conferenceStage ? (
        <>
          <StyledHeader>
            <p>Conferir cartela</p>
          </StyledHeader>
          <StyledBody>
            <div className="scroll-container">
              <div className="search-input">
                <Input
                  img={BlueKey}
                  tipText="Insira o token da cartela"
                  value={token}
                  onChange={handleTokenChange}
                  iconText={false}
                  placeholder="Token"
                  data-cy="input-token"
                  className="search-input"
                />
              </div>
              <StyledRulesContainer data-cy="rules-container">
                <StyledRuleTipText>
                  <img src={CheckOutline} />
                  <p>Indique as regras que se aplicam a esta rodada</p>
                </StyledRuleTipText>
                <RuleContainer
                  activeRules={activeRules}
                  setActiveRules={setActiveRules}
                />
              </StyledRulesContainer>
            </div>
          </StyledBody>
          <StyledFooter data-cy="check-footer">
            <Button
              label="Voltar ao sorteador"
              onClick={handleCloseButton}
              clear
            />
            <Button
              label="Conferir cartela"
              icon={BlueCheck}
              onClick={handleConferenceStage}
              disabled={isActionDisabled}
            />
          </StyledFooter>
        </>
      ) : (
        <CardResult
          token={token}
          handleCloseButton={handleCloseButton}
          activeRules={activeRules}
        />
      )}
    </StyledContainer>
  )
}

export default CheckCardModal
