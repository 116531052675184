import React, { createContext, useState } from 'react'

export const BingoDrawerContext = createContext()

export const BingoDrawerProvider = ({ children }) => {
  const [orderedDrawnNumbers, setOrderedDrawnNumbers] = useState([])
  const [drawnNumbers, setDrawnNumbers] = useState({
    B: [],
    I: [],
    N: [],
    G: [],
    O: [],
  })

  return (
    <BingoDrawerContext.Provider
      value={{
        orderedDrawnNumbers,
        setOrderedDrawnNumbers,
        drawnNumbers,
        setDrawnNumbers,
      }}
    >
      {children}
    </BingoDrawerContext.Provider>
  )
}
