import { useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { Row, Col } from 'react-styled-flexboxgrid'
import { BingoCage, Caller, Modal } from '../../components'
import { StyledBingoDrawPage } from './style'
import { numberSorter, decrypt, copyToClipboard } from '../../utils'
import CheckCardModal from '../CheckCardModal'
import {
  AltCopyBlue,
  CheckOutlineWhite,
  WarningIcon,
} from '../../assets/images'

const BingoDrawPage = () => {
  const { token } = useParams()
  const decrypted_token = decrypt(token)
  const navigate = useNavigate()

  const [ballCage, setBallCage] = useState([...numberSorter(decrypted_token)])
  const { isDropdownVisible, isModal, setModal, optionDropdown } =
    useContext(AppContext)

  const removeBallcage = (newBallCage) => {
    setBallCage(newBallCage)
  }

  const title = 'Você está prestes a sair do sorteador!'
  const subtitle =
    'Ao sair desta página você terá que inserir novamente o token \
atual para continuar nesse sorteio. Deseja realmente sair?'

  const handleCopyClick = () => {
    copyToClipboard(decrypted_token)
  }

  const nextPage = () => {
    const homeOption = () => {
      setModal(false)
      navigate('/home')
    }
    const logoutOption = () => {
      sessionStorage.setItem('access_token', null)
      setModal(false)
      navigate('/login')
    }
    optionDropdown === 'home' ? homeOption() : logoutOption()
  }

  return (
    <StyledBingoDrawPage>
      <Row className="table-bingo">
        <Col sm={7} className="wrapper-left">
          <BingoCage />
          {isModal && (
            <Modal
              imgBlocText={WarningIcon}
              title={title}
              subtitle={subtitle}
              iconButtonSecondary={AltCopyBlue}
              labelButtonSecondary="Copiar token"
              handleButtonSecondary={handleCopyClick}
              iconButtonPrimary={CheckOutlineWhite}
              labelButtonPrimary="Sair do sorteador"
              handleButtonPrimary={nextPage}
            />
          )}
        </Col>
        <Col sm={5} className="wrapper-right">
          <Caller ballCage={ballCage} removeBallcage={removeBallcage} />
        </Col>
      </Row>
      {isDropdownVisible && <CheckCardModal />}
    </StyledBingoDrawPage>
  )
}

export default BingoDrawPage
