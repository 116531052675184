import { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [isDropdownVisible, setDropdownVisibility] = useState(false)
  const [optionDropdown, setOptionDropdown] = useState('')
  const [isModal, setModal] = useState(false)
  const [isActionDisabled, setActionDisabled] = useState(true)
  const [userInfo, setUserInfo] = useState({})
  const location = useLocation()

  const resetState = () => {
    setDropdownVisibility(false)
    setActionDisabled(true)
  }

  useEffect(() => {
    resetState()
  }, [location])

  return (
    <AppContext.Provider
      value={{
        isDropdownVisible,
        setDropdownVisibility,
        optionDropdown,
        setOptionDropdown,
        isModal,
        setModal,
        isActionDisabled,
        setActionDisabled,
        resetState,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
