import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'
import { ArrowLeftIcon, ArrowLeftWhiteIcon } from '../../assets/images'

export const StyledButton = styled.button`
  background-color: ${theme.colors.white};
  color: ${theme.colors.turquoise};
  height: 40px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${theme.colors.turquoise};
  opacity: 1px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.solitude};
    box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};
  }

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 20px;
  }

  img {
    width: 14px;
    height: 14px;
    content: url(${ArrowLeftIcon});
  }

  @media (max-width: 540px) {
    width: 28px;
    height: 28px;
    border: none;
    border-radius: 100%;
    background-color: ${theme.colors.midnightBlue};

    img {
      width: 12px;
      height: 12px;
      content: url(${ArrowLeftWhiteIcon});
    }

    span {
      display: none;
    }

    &:hover {
      background-color: ${theme.colors.turquoise};
      box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};
    }
  }
`
