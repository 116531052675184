export const decrypt = (content) => {
  const crypto = require('crypto')
  const envs = process.env

  const mykey = crypto.createDecipher(
    envs.REACT_APP_ALGORITHM,
    envs.REACT_APP_CRYPT_PASSWORD
  )
  let decrypted = mykey.update(content, 'hex', 'utf8')
  decrypted += mykey.final('utf8')

  return decrypted
}
