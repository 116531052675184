import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledCardResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  .result-time {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
  }

  .card-base {
    margin: 0;
    height: 438px !important;
    width: 400px !important;
    display: flex;
    justify-content: center;

    div {
      flex: none;
    }

    .grid-card {
      padding: 0;
      margin: 0 4px 0 0;
      flex: unset;
      height: 402px;
      width: 333px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 333px;
        height: 57px;

        .card-header,
        .card-cell,
        .card-img {
          width: 57px;
          height: 57px;
          border-radius: 2.5px;
        }

        .card-img img {
          width: 39.26px;
          height: 38.78px;
        }
      }
    }
  }
`

export const StyledFooterContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.solitude};
  border-top: 1px solid ${theme.colors.bluishGray};
  padding: 30px 40px;
  height: 119px;
  width: 670px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: flex-end;

  div:first-child {
    height: 107px;
    width: 681px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${(props) => (props.resultTime ? 'column' : 'unset')};

    .footer-text {
      font-family: Inter;
      font-size: 28px;
      font-weight: 700;
      line-height: 38.4px;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${(props) => (props.loserText ? 'black' : theme.colors.turquoise)};
    }
  }
`

export const StyledWinnerMessage = styled.span`
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: 0.01em;
  text-align: center;

  & + button {
    width: 40%;

    span {
      font-size: 18px;
    }
  }
`

export const StyledResultImg = styled.img`
  width: 105px;
  height: 107px;
  gap: 9.82;
`

export const StyledButtonsFooter = styled.div`
  display: flex;
  gap: 20px;

  button {
    width: 209px;
    height: 44px;
    gap: 10px;
    opacity: 0px;
    flex-direction: row-reverse;

    span {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
    }

    img {
      height: 10px;
      width: 12px;
    }
  }

  button:last-child {
    img:last-child {
      height: 20px;
      width: 20px;
    }
  }
`
