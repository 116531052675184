import { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { useNavigate } from 'react-router-dom'
import { getUserInfo } from '../../utils/getUserInfo'

const PrivateRoute = () => {
  const navigate = useNavigate()
  const { setUserInfo } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const accessToken = sessionStorage.access_token

  useEffect(() => {
    const checkValidity = async () => {
      try {
        const userResponse = await getUserInfo(accessToken)
        setAuthenticated(userResponse.email_verified)
        setUserInfo({ ...userResponse, accessToken: accessToken })
      } catch (error) {
        sessionStorage.removeItem('access_token')
        setUserInfo({})
        setAuthenticated(false)
      } finally {
        setLoading(false)
      }
    }

    checkValidity()
  }, [navigate])

  const nagivateTo = () => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  }

  return loading ? <div>Loading ...</div> : nagivateTo()
}

export default PrivateRoute
