import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  min-height: 90px;
  width: 100%;
`

export const StyledTipText = styled.span`
  margin-bottom: 8px;
  font-size: 16px;
  color: ${theme.colors.cloudBurst};
  align-self: flex-start;
  font-family: 'Inter', sans-serif;
  font-weight: 450;

  @media (max-width: 540px) {
    margin-bottom: 6px;
  }
`

export const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
`

export const StyledIcon = styled.img`
  position: absolute;
  transform: translate(70%, 50%);
  width: 20px;
  height: 20px;
`

export const StyledInput = styled.input`
  padding: 10px 10px 10px 40px;
  color: ${theme.colors.cloudBurst};
  font-size: 16px;
  border: 1px solid ${theme.colors.veryLightGrey};
  border-radius: 5px;
  width: 80%;
`

export const WarningMessage = styled.div`
  text-align: left;
  color: #c01757;
  margin: 4px 0px 4px 0px;
`

export const StyledTipTextImg = styled.div`
  margin-left: 6.5%;
  display: flex;
  gap: 20px;
  img {
    margin-top: -12px;
  }
`
