import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledButton = styled.button`
  background-color: ${(props) =>
    props.clear ? theme.colors.white : theme.colors.turquoise};
  color: ${(props) =>
    props.clear ? theme.colors.turquoise : theme.colors.white};
  border: ${(props) =>
    props.clear ? `1px solid ${theme.colors.turquoise}` : 'none'};
  width: 88%;
  height: 40px;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 4px;
  opacity: 1px;
  font: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.clear ? 'unset' : theme.colors.midnightBlue};
    color: ${(props) =>
      props.clear ? theme.colors.midnightBlue : theme.colors.white};
    border-color: ${(props) =>
      props.clear ? theme.colors.midnightBlue : 'none'};
    box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};
  }

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 20px;
  }

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 540px) {
    width: 91%;
  }
`
