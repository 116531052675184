/* https://www.color-blindness.com/color-name-hue/ */

const theme = {
  colors: {
    // blue
    babyBlue: '#587CE2',
    turquoise: '#3A56DC',
    midnightBlue: '#191A69',
    navyBlue1: '#121346',
    softSlateBlue: '#587CE20D',
    jordyBlue: '#7A8DE7',
    bluishGray: '#96A0AA',
    solitude: '#F3F7F9',
    solitudeBlue: '#E2E8F0',

    // gray
    darkGray: '#201E1F',
    neroGray: '#212121',
    cloudBurst: '#344254',
    linkWater: '#D4DBDF',
    veryLightGrey: '#ccc',
    gainsboro: '#D9D9D9',
    sanJuan: '#41525F',
    lightSlateGrey: '#808D9E',
    lightSolitude: '#F8F8FA',
    eastBay: '#485668',
    raven: '#70757A',

    // green
    harlequin: '#99E399',

    // pink
    pink: '#EA71C5',

    // yellow
    energyYellow: '#F6D353',

    // white
    white: '#FFFFFF',
    whiteSmoke: '#F4F4F4',

    // dark
    melanzen: '#201E1F',

    // red
    cinnabar: '#DA3333',
  },

  rgba: {
    black025: 'rgba(0, 0, 0, 0.25)',
    black01: 'rgba(0, 0, 0, 0.1)',
    black024: 'rgba(0, 0, 0, 0.24)',

    // blue
    babyBlue: 'rgba(88, 124, 226, 0.1)',
    babyBlue005: 'rgba(88, 124, 226, 0.05)',
    blueRaven05: 'rgba(112, 117, 122, 0.50)',
    blueTangaroa01: 'rgba(16, 24, 40, 0.1)',
    blueTangaroa004: 'rgba(16, 24, 40, 0.04)',
    linkWater093: 'rgba(212, 219, 223, 0.93)',
  },

  grid: {
    flexboxgrid: {
      gridSize: 12,
    },
  },
}

export default theme
