export const crypt = (content) => {
  const crypto = require('crypto')
  const envs = process.env

  const mykey = crypto.createCipher(
    envs.REACT_APP_ALGORITHM,
    envs.REACT_APP_CRYPT_PASSWORD
  )
  let encrypted = mykey.update(content, 'utf8', 'hex')
  encrypted += mykey.final('hex')

  return encrypted
}

export const cryptToken = (token) => {
  const today = new Date()
  const encrypted_token = crypt(token + '_' + today.getTime())

  return encrypted_token
}
