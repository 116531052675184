import React from 'react'
import { Button } from '../../components'
import {
  StyledLoginGoogle,
  StyledContainer,
  StyledContent,
  StyledImg,
  StyledTitle,
} from './style'
import {
  ArrowRightIcon,
  BackgroundImage,
  LogoRebaseIcon,
} from '../../assets/images'
import { signInGoogle } from '../../utils'

const LoginGoogle = () => {
  const login = signInGoogle()

  return (
    <StyledLoginGoogle background={BackgroundImage}>
      <StyledContainer>
        <StyledContent data-cy="login-content">
          <StyledImg
            src={LogoRebaseIcon}
            className="logo"
            alt="logo da rebase"
          />
          <StyledTitle data-cy="login-hello">
            Olá, boas vindas ao Rebase Bingo!
          </StyledTitle>
          <Button
            label="Acessar com Google"
            onClick={login}
            customButton={true}
            icon={ArrowRightIcon}
          />
        </StyledContent>
      </StyledContainer>
    </StyledLoginGoogle>
  )
}

export default LoginGoogle
