import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import { getUserInfo } from './getUserInfo'

export const signInGoogle = () => {
  const navigate = useNavigate()
  const { setUserInfo } = useContext(AppContext)

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userResponse = await getUserInfo(tokenResponse.access_token)
        setUserInfo({ ...userResponse, ...tokenResponse })
        sessionStorage.setItem('access_token', tokenResponse.access_token)
        navigate('/home')
      } catch {
        return null
      }
    },
    onError: () => {
      return null
    },
  })

  return login
}
