import React, { useState, useContext } from 'react'
import {
  StyledInputWrapper,
  StyledInput,
  StyledTipText,
  StyledInputContainer,
  StyledIcon,
  WarningMessage,
  StyledTipTextImg,
} from './style'
import { KeyIcon } from '../../assets/images'
import { AppContext } from '../../context/AppContext'

const Input = ({
  tipText,
  value,
  onChange,
  placeholder,
  img,
  iconText = true,
}) => {
  const [warning, setWarning] = useState(false)
  const { setActionDisabled } = useContext(AppContext)
  const regex = /^[a-zA-Z0-9\-_\.]+$/

  const handleInputChange = (e) => {
    const { value } = e.target
    const isInvalid = !regex.test(value)
    setWarning(value && isInvalid)
    setActionDisabled(!value || isInvalid)
    onChange(e)
  }

  return (
    <StyledInputWrapper className="input-wrapper" data-cy="input-wrapper">
      <StyledTipTextImg className="input-tipTexImg">
        {img && <img src={img} />}
        {tipText && <StyledTipText>{tipText}</StyledTipText>}
      </StyledTipTextImg>
      <StyledInputContainer
        className="input-container"
        data-cy="input-container"
      >
        {iconText && <StyledIcon src={KeyIcon} alt="key icon" />}
        <StyledInput
          type="text"
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      </StyledInputContainer>
      {warning && (
        <WarningMessage className="invalid-message">
          Carácter inválido
        </WarningMessage>
      )}
    </StyledInputWrapper>
  )
}

export default Input
