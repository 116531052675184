import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  background: ${theme.rgba.blueRaven05};

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 481px;
    padding-top: 32px;
    gap: 20px;
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 20px 24px -4px ${theme.rgba.blueTangaroa01};

    .close-icon {
      position: absolute;
      transform: translate(930%, -100%);
      cursor: pointer;
    }

    .div-warning {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .div-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 20px;
        gap: 8px;
        color: ${theme.colors.sanJuan};
        font-size: 16px;

        .title {
          font-weight: 700;
          line-height: 28px;
        }

        .subTitle {
          text-align: center;
          padding: 0px 5px;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }

    .div-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 32px 24px;
      gap: 16px;
      border-top: 1px solid ${theme.colors.solitudeBlue};

      button {
        width: 211px;
        height: 46px;
        padding: 10px 16px;
        gap: 10px;
        flex-direction: row-reverse;

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .container {
      width: 85%;

      .close-icon {
        position: absolute;
        transform: translate(590%, -100%);
      }

      .div-warning {
        gap: 15px;

        .div-text {
          font-size: 13px;
        }
      }

      .div-buttons {
        padding: 20px;

        button {
          width: 85%;
          padding: 10px;
          gap: 8px;

          span {
            width: 90px;
            font-size: 12px;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
`
