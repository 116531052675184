import { shuffleArray } from '.'

export const shuffleNumbers = (hashNumbers, token) => {
  const shuffledNumbers = {}

  Object.keys(hashNumbers).forEach((key) => {
    // Copiando o array original para não modificar o original
    const custonArray = [...hashNumbers[key]]
    // Embaralhando o array 1x
    const shuffledArray = shuffleArray(custonArray, token)
    // Atribuindo o array embaralhado ao novo objeto
    shuffledNumbers[key] = shuffledArray
  })

  return shuffledNumbers
}
