import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer, Header } from '../../components'
import { StyledContent } from './style'
import { RebaseMessage, RebaseLogoWhite } from '../../assets/images'
import { useLocation } from 'react-router-dom'

const LayoutApp = () => {
  const location = useLocation()
  const [showHeaderFooter, setShowHeaderFooter] = useState(false)

  useEffect(() => {
    setShowHeaderFooter(location.pathname !== '/')
  }, [location.pathname])

  return (
    <>
      {showHeaderFooter && <Header />}
      <StyledContent>
        <Outlet />
      </StyledContent>
      {showHeaderFooter && (
        <Footer leftImage={RebaseLogoWhite} rightImage={RebaseMessage} />
      )}
    </>
  )
}

export default LayoutApp
