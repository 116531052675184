import axios from 'axios'

export const getUserInfo = async (access_token) => {
  const userResponse = await axios.get(
    process.env.REACT_APP_GOOGLE_API_USER_INFO_URL,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  )
  return userResponse.data
}
