import { Route, Routes, Navigate } from 'react-router-dom'
import {
  BingoCardPage,
  BingoDrawPage,
  HomePage,
  LoginGoogle,
  LayoutApp,
} from '../../components'
import { BingoDrawerProvider } from '../../context/BingoDrawerContext'
import PrivateRoute from './PrivateRoutes'

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginGoogle />} />
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<HomePage />} />
        <Route element={<LayoutApp />}>
          <Route path="/cartela/:token" element={<BingoCardPage />} />
          <Route
            path="/sorteador/:token"
            element={
              <BingoDrawerProvider>
                <BingoDrawPage />
              </BingoDrawerProvider>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

export default RoutesApp
