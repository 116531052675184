import React from 'react'
import { StyledButton } from './style'

const Button = ({
  label,
  onClick,
  icon,
  clear,
  disabled,
  className = 'button',
}) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      data-cy="button-div"
      clear={clear}
      disabled={disabled}
    >
      <span>{label}</span>
      {icon && <img src={icon} alt="arrow icon" />}
    </StyledButton>
  )
}

export default Button
