/* eslint-disable no-console */
export const copyToClipboard = async (text) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      console.error('Erro ao copiar o texto: ', err)
    }
  } else {
    fallbackCopy(text)
  }
}

const fallbackCopy = async (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.position = 'fixed'
  textArea.style.top = '0'
  textArea.style.left = '0'
  document.body.appendChild(textArea)
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Erro ao copiar o texto com fallback: ', err)
  }

  document.body.removeChild(textArea)
}
