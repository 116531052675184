import React, { useState, useRef, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { StyledButton, StyledContainer, StyledContent } from './style'
import { DropdownOption } from '../'
import { copyToClipboard, decrypt, cryptToken } from '../../utils'
import { useOutsideClick } from '../../hooks'
import {
  AltCopy,
  DropdownOpener,
  HelloHand,
  HomeIcon,
  Logout,
  NewIcon,
  SearchIcon,
} from '../../assets/images'

const Dropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [activeOption, setActiveOption] = useState('')
  const { userInfo, setDropdownVisibility, setModal, setOptionDropdown } =
    useContext(AppContext)

  const dropdownRef = useRef(null)
  const { token } = useParams()
  const decrypted_token = decrypt(token)

  const location = useLocation()
  const isBingoDrawPage = location.pathname.startsWith('/sorteador')
  const isBingoCardPage = location.pathname.startsWith('/cartela')

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
    setActiveOption('')
  }

  const closeDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false)
      setActiveOption('')
    }
  }

  useOutsideClick(dropdownRef, closeDropdown, showDropdown)

  const handleCopyClick = () => {
    copyToClipboard(decrypted_token)
  }

  const handleCheckModal = () => {
    setDropdownVisibility(true)
    closeDropdown()
  }

  const handleNewCard = () => {
    setActiveOption('newCard')
    setOptionDropdown(cryptToken(decrypted_token.split('_')[0]))
    setModal(true)
  }

  return (
    <StyledContainer ref={dropdownRef} data-cy="dropdown">
      <img src={HelloHand} alt="hello" />
      <span>{userInfo.name}</span>
      <StyledButton onClick={toggleDropdown} data-cy="dropdown-opener">
        <img src={DropdownOpener} alt="dropdown-opener" />
      </StyledButton>
      <StyledContent
        show={showDropdown}
        drawPage={isBingoDrawPage}
        data-cy="dropdown-content"
      >
        <DropdownOption
          img={HomeIcon}
          altImg="home-img"
          label="Home"
          action={() => {
            setActiveOption('home')
            setOptionDropdown('home')
            setModal(true)
          }}
          isActive={activeOption === 'home'}
        />
        {isBingoDrawPage && (
          <DropdownOption
            img={SearchIcon}
            imgAlt="check-card"
            label="Conferir cartela"
            action={() => {
              setActiveOption('check')
              handleCheckModal()
            }}
            isActive={activeOption === 'check'}
          />
        )}
        <DropdownOption
          img={AltCopy}
          imgAlt="copy-token"
          label="Copiar token"
          action={() => {
            setActiveOption('copy')
            handleCopyClick()
          }}
          isActive={activeOption === 'copy'}
        />
        {isBingoCardPage && (
          <DropdownOption
            img={NewIcon}
            altImg="new-card"
            label="Nova cartela"
            action={() => {
              handleNewCard()
            }}
            isActive={activeOption === 'newCard'}
          />
        )}
        <DropdownOption
          img={Logout}
          imgAlt="sign-out"
          label="Sair"
          action={() => {
            setActiveOption('logout')
            setOptionDropdown('logout')
            setModal(true)
          }}
          isActive={activeOption === 'logout'}
        />
      </StyledContent>
    </StyledContainer>
  )
}

export default Dropdown
