import { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { StyledBingoCardPage } from './style'
import { Card, Modal } from '../../components'
import {
  AltCopyBlue,
  CheckOutlineWhite,
  WarningIcon,
} from '../../assets/images'
import {
  copyToClipboard,
  decrypt,
  generateCard,
  hashNumbers,
  shuffleNumbers,
} from '../../utils'

const BingoCardPage = () => {
  const { token } = useParams()
  const { isModal, setModal, optionDropdown } = useContext(AppContext)

  const decrypted_token = decrypt(token)
  const shuffled = shuffleNumbers(hashNumbers, decrypted_token)
  const cardNumbers = generateCard(shuffled)
  const navigate = useNavigate()

  const title = 'Você está prestes a sair da cartela!'
  const subtitle =
    'Se você continuar, o seu token atual será perdido e não será possível conferir sua cartela. \
Deseja realmente sair?'

  const handleCopyClick = () => {
    copyToClipboard(decrypted_token)
  }

  const nextPage = () => {
    switch (optionDropdown) {
      case 'home':
        setModal(false)
        navigate('/home')
        break
      case 'logout':
        sessionStorage.setItem('access_token', null)
        setModal(false)
        navigate('/login')
        break
      default:
        setModal(false)
        navigate(`/cartela/${optionDropdown}`)
        window.location.reload(true)
        break
    }
  }

  return (
    <StyledBingoCardPage data-cy="bingo-card-page">
      <Card cardNumbers={cardNumbers} token={decrypted_token} />
      {isModal && (
        <Modal
          imgBlocText={WarningIcon}
          title={title}
          subtitle={subtitle}
          iconButtonSecondary={AltCopyBlue}
          labelButtonSecondary="Copiar token"
          handleButtonSecondary={handleCopyClick}
          iconButtonPrimary={CheckOutlineWhite}
          labelButtonPrimary="Sair da cartela"
          handleButtonPrimary={nextPage}
        />
      )}
    </StyledBingoCardPage>
  )
}

export default BingoCardPage
