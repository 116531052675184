import { StyledCard } from './style'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import LogoBlue from '../../assets/images/LogoBlue.svg'
import React, { useState } from 'react'
import CardCell from '../CardCell'

const COLUMNS = ['B', 'I', 'N', 'G', 'O']

const Card = ({ cardNumbers }) => {
  const [columns, setColumns] = useState({
    B: Array(5).fill(false),
    I: Array(5).fill(false),
    N: Array(4).fill(false),
    G: Array(5).fill(false),
    O: Array(5).fill(false),
  })

  const handleCellClick = (column, number, clicked) => {
    const index = cardNumbers[column].indexOf(number)

    if (index !== -1) {
      const newColumns = { ...columns }
      newColumns[column][index] = clicked
      setColumns(newColumns)
    }
  }

  const isColumnComplete = (column) => {
    if (columns[column].every((cell) => cell)) {
      return 'bingo'
    }
  }

  const renderHeader = () => (
    <Row>
      {COLUMNS.map((letter) => (
        <Col key={letter} className={`card-header ${isColumnComplete(letter)}`}>
          <p className={isColumnComplete(letter)}>{letter}</p>
        </Col>
      ))}
    </Row>
  )

  const renderCell = (column, rowIndex) => {
    if (column === 'N' && rowIndex === 2) {
      return (
        <Col
          className="card-img"
          key={`${column}-${rowIndex}`}
          data-column={column}
        >
          <img src={LogoBlue} alt="logo da rebase" />
        </Col>
      )
    }

    const adjustedRowIndex =
      column === 'N' && rowIndex > 2 ? rowIndex - 1 : rowIndex

    return (
      <CardCell
        key={`${column}-${rowIndex}`}
        cardNumber={cardNumbers[column][adjustedRowIndex]}
        column={column}
        onCellClick={handleCellClick}
        className={`card-cell ${column}`}
      />
    )
  }

  const renderRows = () => {
    const rows = columns.B.length

    return Array.from({ length: rows }).map((_, rowIndex) => (
      <Row key={rowIndex}>
        {COLUMNS.map((column) => renderCell(column, rowIndex))}
      </Row>
    ))
  }

  return (
    <StyledCard data-cy="card">
      <div className="container">
        <div className="card-base">
          <Grid className="grid-card">
            {renderHeader()}
            {renderRows()}
          </Grid>
        </div>
      </div>
    </StyledCard>
  )
}

export default Card
