import React from 'react'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Dropdown } from '../'
import { LogoBlue } from '../../assets/images'
import { StyledHeader, StyledImage } from './style'

const Header = () => {
  return (
    <StyledHeader data-cy="header">
      <Grid fluid={true} data-cy="header-grid" className="grid">
        <Row className="wrap-header" data-cy="wrap-header">
          <Col sm={6} className="left-logo" data-cy="left-logo">
            <StyledImage
              src={LogoBlue}
              alt="logo da rebase"
              data-cy="logo-icon"
            />
          </Col>
          <Col sm={6} className="right-logo" data-cy="dropdown-wraper">
            <Dropdown />
          </Col>
        </Row>
      </Grid>
    </StyledHeader>
  )
}

export default Header
