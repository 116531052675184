import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { crypt, cryptToken } from '../../utils'
import { MenuButton, Button, Input, Footer } from '../../components'
import theme from '../../assets/styles/theme/default'
import { AppContext } from '../../context/AppContext'
import {
  StyledHomePage,
  StyledContainer,
  StyledContent,
  StyledImg,
  StyledSubTitle,
  StyledButtonsWrapper,
  StyledFooter,
  StyledFormWrapper,
} from './style'
import {
  LogoRebaseIcon,
  CardIcon,
  DrawerIcon,
  RebaseCopyrightIcon,
  RebaseMessageLoginIcon,
  BackgroundImage,
  ArrowRightIcon,
} from '../../assets/images'

const HomePage = () => {
  const [token, setToken] = useState('')
  const [buttonLabel, setButtonLabel] = useState('')
  const [selectedButton, setSelectedButton] = useState('')
  const { isActionDisabled, userInfo } = useContext(AppContext)
  const navigate = useNavigate()

  const handleTokenChange = (e) => {
    setToken(e.target.value)
  }

  const handlePlayerClick = () => {
    const email = userInfo.email.split('@')[0]
    const encrypted_token = cryptToken(email)

    navigate(`/cartela/${encrypted_token}`)
  }

  const handleDrawerClick = () => {
    setSelectedButton('drawer')
    setButtonLabel('Sortear bingo')
  }

  const handleButtonClick = () => {
    const encrypted_token = crypt(token)
    navigate(`/sorteador/${encrypted_token}`)
  }

  return (
    <StyledHomePage background={BackgroundImage}>
      <StyledContainer>
        <StyledContent data-cy="content">
          <StyledImg
            src={LogoRebaseIcon}
            className="logo"
            alt="logo da rebase"
            selected={buttonLabel}
          />
          <StyledSubTitle selected={buttonLabel} data-cy="app-hello">
            O que gostaria de fazer?
          </StyledSubTitle>
          <StyledButtonsWrapper className="buttons" data-cy="buttons">
            <MenuButton
              label="Jogar bingo"
              text="Acessar a cartela"
              alt={'CardIcon'}
              img={CardIcon}
              onClick={handlePlayerClick}
            />
            <MenuButton
              label="Sortear"
              text="Acessar com Token"
              alt={'DrawerIcon'}
              img={DrawerIcon}
              onClick={handleDrawerClick}
              selected={selectedButton === 'drawer'}
            />
          </StyledButtonsWrapper>
          <StyledFormWrapper label={buttonLabel} data-cy="form-wrapper">
            <Input
              tipText={'Para acessar o sorteador digite o token'}
              value={token}
              onChange={handleTokenChange}
              placeholder="Digite o token"
              data-cy="input-token"
            />
            <Button
              label={'Sortear bingo'}
              icon={ArrowRightIcon}
              onClick={handleButtonClick}
              disabled={isActionDisabled}
            />
          </StyledFormWrapper>
        </StyledContent>
        <StyledFooter className="footer">
          <Footer
            backgroundcolor={theme.colors.solitude}
            leftImage={RebaseCopyrightIcon}
            rightImage={RebaseMessageLoginIcon}
          />
        </StyledFooter>
      </StyledContainer>
    </StyledHomePage>
  )
}

export default HomePage
