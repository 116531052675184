import styled from 'styled-components'

export const StyledBingoDrawPage = styled.div`
  display: flex;
  justify-content: center;

  .wrapper-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .table-bingo {
    width: 90%;
    justify-content: center;
  }

  //mobile
  @media (max-width: 390px) {
    height: 80vh;

    .table-bingo {
      align-content: center;
    }
  }

  @media (max-width: 820px) {
    height: 90vh;

    .table-bingo {
      align-items: center;
    }
  }
`
