import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import './assets/styles/defaultStyle.css'
import { App } from './components'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './context/AppContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StyleSheetManager shouldForwardProp={isPropValid}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </StyleSheetManager>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
