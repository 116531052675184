import React, { useContext, useEffect, useState } from 'react'
import { Button, Card } from '../../components/index'
import {
  StyledCardResultContainer,
  StyledFooterContainer,
  StyledButtonsFooter,
  StyledResultImg,
  StyledWinnerMessage,
} from './style'
import {
  MagnifyingGlass,
  WinnerResult,
  LoserResult,
  BlueCheck,
  ArrowLeftIcon,
} from '../../assets/images'
import {
  generateCard,
  hashNumbers,
  shuffleNumbers,
  checkCard,
} from '../../utils'
import { BingoDrawerContext } from '../../context/BingoDrawerContext'

const CardResult = ({ token, handleCloseButton, activeRules }) => {
  const shuffled = shuffleNumbers(hashNumbers, token)
  const cardNumbers = generateCard(shuffled)

  const [nextStep, setNextStep] = useState(0)

  const { orderedDrawnNumbers, drawnNumbers } = useContext(BingoDrawerContext)

  useEffect(() => {
    const handleStage = async () => {
      const step = await checkCard({
        cardNumbers,
        orderedDrawnNumbers,
        drawnNumbers,
        activeRules,
      })
      setNextStep(step)
    }

    handleStage()
  }, [])

  return (
    <>
      <StyledCardResultContainer resultTime={nextStep > 0}>
        <Card cardNumbers={cardNumbers} token={token} />
        {nextStep === 1 && (
          <StyledResultImg
            src={WinnerResult}
            alt="winner result"
            className="result-time"
          />
        )}
        {nextStep === 2 && (
          <StyledResultImg
            src={LoserResult}
            alt="loser result"
            className="result-time"
          />
        )}
      </StyledCardResultContainer>
      <StyledFooterContainer
        data-cy="conference-footer"
        loserText={nextStep === 2}
        resultTime={nextStep > 0}
      >
        <div>
          {nextStep === 0 && (
            <img src={MagnifyingGlass} alt="Magnifying Glass" />
          )}
          <span className="footer-text">
            {(() => {
              if (nextStep === 0) return 'Conferindo cartela...'
              if (nextStep === 1) return 'Bingouuu :)'
              return 'Não foi dessa vez :('
            })()}
          </span>
          {nextStep === 1 && (
            <>
              <StyledWinnerMessage>
                Parabéns, aproveite seu premio!
              </StyledWinnerMessage>
              <Button
                label="Voltar para cartela"
                onClick={() => {
                  setNextStep(0)
                }}
                icon={ArrowLeftIcon}
                clear
              />
            </>
          )}
          {nextStep === 2 && (
            <StyledButtonsFooter>
              <Button
                label="Voltar para cartela"
                onClick={() => {
                  setNextStep(0)
                }}
                icon={ArrowLeftIcon}
                clear
              />
              <Button
                label="Segue o jogo"
                icon={BlueCheck}
                onClick={handleCloseButton}
              />
            </StyledButtonsFooter>
          )}
        </div>
      </StyledFooterContainer>
    </>
  )
}

export default CardResult
