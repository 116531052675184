import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledFooter = styled.div`
  background-color: ${(props) =>
    props.backgroundcolor || theme.colors.turquoise};
  width: 100%;
  height: 64px;

  img {
    height: 16px;
    padding: 24px 0px;
  }

  .wrap-footer {
    height: 100%;
  }

  .left-logo {
    display: flex;
    justify-content: flex-start;
  }

  .right-logo {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 540px) {
    .wrap-footer {
      display: flex;
      justify-content: center;
    }

    .left-logo {
      display: none;
    }

    .right-logo {
      padding: 0px;

      img {
        width: 100%;
      }
    }
  }
`
