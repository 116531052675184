import { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { StyledModal } from './style'
import { Button } from '../../components'
import { CloseIcon } from '../../assets/images'

const Modal = ({
  imgBlocText,
  title,
  subtitle,
  iconButtonSecondary,
  labelButtonSecondary,
  handleButtonSecondary,
  iconButtonPrimary,
  labelButtonPrimary,
  handleButtonPrimary,
}) => {
  const [showModal, setShowModal] = useState(true)
  const { isModal, setModal } = useContext(AppContext)

  const closeButtonIcon = () => {
    setShowModal(false)
    setModal(false)
  }

  useEffect(() => {
    setShowModal(true)
  }, [isModal])

  return (
    <>
      {showModal && (
        <StyledModal className="modal" data-cy="modal">
          <div className="container">
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={closeButtonIcon}
              className="close-icon"
            />
            <div className="div-warning">
              <img src={imgBlocText} alt="image-for-text" />
              <div className="div-text">
                <div className="title">{title}</div>
                <div className="subTitle">{subtitle}</div>
              </div>
            </div>
            <div className="div-buttons">
              <Button
                className="button-secondary"
                label={labelButtonSecondary}
                icon={iconButtonSecondary}
                onClick={handleButtonSecondary}
                clear
              />
              <Button
                className="button-primary"
                label={labelButtonPrimary}
                icon={iconButtonPrimary}
                onClick={handleButtonPrimary}
              />
            </div>
          </div>
        </StyledModal>
      )}
    </>
  )
}

export default Modal
