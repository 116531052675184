import React, { useState } from 'react'
import { StyledCardCell } from './style'
import { useLocation } from 'react-router-dom'

const CardCell = ({ cardNumber, column, onCellClick, ...props }) => {
  const [marked, setMarked] = useState(false)
  const location = useLocation()

  const handleClick = () => {
    if (location.pathname.startsWith('/sorteador')) return
    const newState = !marked
    setMarked(newState)
    onCellClick(column, cardNumber, newState)
  }

  return (
    <StyledCardCell
      marked={marked ? 'true' : undefined}
      onClick={handleClick}
      className={`card-cell ${column}`}
      {...props}
    >
      <p>{cardNumber}</p>
    </StyledCardCell>
  )
}

export default CardCell
