import { useEffect, useContext } from 'react'
import { AppContext } from '../context/AppContext'

export const useOutsideClick = (ref, onOutsideClick, condition) => {
  const { resetState } = useContext(AppContext)

  useEffect(() => {
    if (!condition) return

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
        resetState()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onOutsideClick, resetState, condition])
}
