import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledHeader = styled.div`
  background-color: ${theme.colors.white};
  height: 64px;
  box-shadow: 0px 1px 3px 0px ${theme.rgba.black01};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .grid {
    padding: 0px;
    margin: 0px;
    width: 100vw;
  }

  .wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 31px;
    margin: 0;
  }

  .left-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  .right-logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media (max-width: 540px) {
    .left-logo {
    }

    .right-logo {
    }

    .wrap-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 12px;
    }
  }
`

export const StyledImage = styled.img`
  height: 32px;

  @media (max-width: 540px) {
    display: none;
  }
`

export const StyledButtonContent = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTokenWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`

export const StyledTokenContent = styled.div`
  display: flex;
  margin-right: 8px;
  gap: 6px;

  .title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }

  .token {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  @media (max-width: 540px) {
    padding: 0px;
    margin-right: 6px;
  }
`

export const StyledCopyButton = styled.button`
  background-color: ${theme.colors.white};
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  border-color: ${theme.colors.gainsboro};
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.solitude};
    box-shadow: 0px 4px 4px 0px ${theme.rgba.black025};
  }

  img {
    height: 16px;
    width: 16px;
  }

  @media (max-width: 540px) {
    width: 28px;
    height: 28px;

    img {
      height: 12px;
      width: 12px;
    }
  }
`
export const StyledCardSearchButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
`
