import React from 'react'
import { StyledButton } from './style'
import { ArrowLeftIcon } from '../../assets/images'
import { useNavigate } from 'react-router-dom'

const HomeButton = ({ label }) => {
  const navigate = useNavigate()
  const HomePage = () => {
    navigate(`/`)
  }

  return (
    <StyledButton onClick={HomePage} data-cy="home-button">
      <img src={ArrowLeftIcon} alt="arrow icon" />
      <span>{label}</span>
    </StyledButton>
  )
}

export default HomeButton
