import {
  paintDrawnNumbers,
  checkDiagonalBingo,
  checkFullBingo,
  checkHorizontalBingo,
  checkVerticalBingo,
  checkTipsBingo,
} from './conferenceMethods'

export const checkCard = async ({
  cardNumbers,
  orderedDrawnNumbers,
  drawnNumbers,
  activeRules,
}) => {
  const rulesFunctions = {
    FullCard: checkFullBingo,
    TipsCard: checkTipsBingo,
    DiagonalCard: checkDiagonalBingo,
    HorizontalLine: checkHorizontalBingo,
    VerticalLine: checkVerticalBingo,
  }

  drawnNumbers = Object.values(drawnNumbers).flat()

  const isWinner = []

  if (activeRules.includes('FullCard')) {
    isWinner.push(checkFullBingo(cardNumbers, drawnNumbers))
  } else {
    activeRules.some((rule) => {
      const checkFunction = rulesFunctions[rule]
      isWinner.push(checkFunction(cardNumbers, drawnNumbers))
    })
  }

  await paintDrawnNumbers(cardNumbers, orderedDrawnNumbers)

  return isWinner.some((element) => element == true) ? 1 : 2
}
