import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledCheckBorder = styled.label`
  border: 0.7px solid
    ${(props) =>
      props.isChecked && !props.hasFullCard
        ? theme.colors.turquoise
        : theme.colors.linkWater};
  padding: ${(props) => (props.QuintupleCard ? '7px 14px' : '7px 8px')};
  border-radius: 8px;
  height: 196px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.QuintupleCard ? 'flex-start' : 'center')};
  justify-content: center;
  gap: 9px;
  background-color: ${(props) =>
    props.isChecked && !props.hasFullCard
      ? theme.colors.softSlateBlue
      : 'transparent'};
  cursor: pointer;

  &:hover {
    border-color: ${(props) =>
      props.hasFullCard ? theme.colors.linkWater : theme.colors.turquoise};
    background-color: ${(props) =>
      props.hasFullCard ? 'unset' : theme.colors.solitude};

    p {
      color: ${theme.colors.turquoise};
    }
  }

  img {
    width: ${(props) => {
      if (props.DoubleCard) return '240px'
    }};
  }

  p {
    color: ${(props) =>
      props.isChecked ? theme.colors.turquoise : theme.colors.eastBay};
  }

  @media (max-width: 540px) {
    height: 24px;
    padding: 0px;
    border: none;

    p {
      font-size: 12px;
      font-weight: 600;
    }

    img {
      display: none;
    }
  }
`

export const StyledInputLabel = styled.div`
  width: ${(props) => {
    if (props.DoubleCard) return '267px'
    if (props.QuintupleCard) return '452px'
    return '162px'
  }};
  height: 46px;
  gap: 6px;
  display: flex;
  align-items: center;

  @media (max-width: 540px) {
    width: 250px;
    height: 24px;
  }
`

export const StyledInput = styled.div`
  width: 26px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    border: 2px solid ${theme.colors.linkWater};
    border-radius: 4px;
    appearance: none;
    cursor: pointer;
    position: relative;
    background-color: ${(props) =>
      props.hasFullCard ? theme.colors.linkWater : theme.colors.white};
  }

  input[type='checkbox']:checked {
    border-color: ${(props) =>
      props.hasFullCard ? theme.colors.linkWater : theme.colors.turquoise};
    background-color: ${(props) =>
      props.hasFullCard ? theme.colors.linkWater : theme.colors.turquoise};
  }

  input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 4px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  @media (max-width: 540px) {
    width: 24px;
    height: 24px;
  }
`

export const StyledLabel = styled.div`
  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 0.16px;
  }
`
