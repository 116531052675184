import styled from 'styled-components'

export const StyledRuleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 690px;
  height: 690px;
  gap: 24px 20px;

  @media (max-width: 540px) {
    justify-content: flex-start;
    width: 300px;
    height: 220px;
    gap: 8px;
  }
`
