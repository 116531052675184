import { Row, Col } from 'react-styled-flexboxgrid'
import { StyledCell } from './style'
import { hashNumbers } from '../../utils'
import { useContext } from 'react'
import { BingoDrawerContext } from '../../context/BingoDrawerContext'

const BingoCage = () => {
  const { drawnNumbers } = useContext(BingoDrawerContext)

  const renderLetters = () => (
    <Row className="wrapper-row">
      {Object.keys(hashNumbers).map((letter) => (
        <Col className="cell letter" key={letter} sm={2}>
          {letter}
        </Col>
      ))}
    </Row>
  )

  const renderNumbers = () => {
    const positions = hashNumbers['B'].length
    const letters = Object.keys(hashNumbers)

    return Array.from({ length: positions }).map((_, position) => (
      <Row className="wrapper-row" key={position}>
        {letters.map((letter) => (
          <Col
            className={`cell number ${isSelectedNumber(letter, position)}`}
            key={letter}
            sm={2}
          >
            {cellnumber(letter, position)}
          </Col>
        ))}
      </Row>
    ))
  }

  const isSelectedNumber = (letter, position) => {
    const number = cellnumber(letter, position)
    return drawnNumbers[letter].includes(number)
  }

  const cellnumber = (letter, position) => {
    return hashNumbers[letter][position]
  }

  return (
    <StyledCell data-cy="bingo-table">
      {renderLetters()}
      {renderNumbers()}
    </StyledCell>
  )
}

export default BingoCage
