import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledLoginGoogle = styled.div`
  display: flex;
  justify-content: right;
  background: url(${(props) => props.background});
  background-size: cover;
  min-height: 100vh;

  @media (max-width: 540px) {
    justify-content: center;
  }
`

export const StyledContainer = styled.div`
  text-align: center;
  background-color: ${theme.colors.white};
  min-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 540px) {
    opacity: 0.96;
    margin: 16px;
  }
`

export const StyledContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  button {
    border-color: inherit;
    cursor: ${(props) => (props.customButton ? 'not-allowed' : 'pointer')};
    height: 56px;
    width: 428px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    @media (max-width: 540px) {
      width: 315px;

      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
    }

    @media (min-width: 1920px) {
      width: 430px;
    }
  }
`

export const StyledImg = styled.img`
  height: 142px;
  width: 154px;

  @media (max-width: 540px) {
    height: 101px;
    width: 115px;
  }
`

export const StyledTitle = styled.h1`
  color: ${theme.colors.darkGray};
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  width: 400px;
  padding-top: 24px;
  padding-bottom: 36px;

  @media (max-width: 540px) {
    font-size: 18px;
    width: 350px;
  }
`
